<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Reporte Postulación</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Reporte Postulación</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div>
                <div class="card-header pt-2 pb-2">
                  <div class="row">
                    <div class="col-md-2">
                      <div class="form-group">
                        <label>Categoría Operación</label>
                        <select
                          placeholder="Tipo Operación"
                          class="form-control form-control-sm shadow-sm"
                          v-model="filtros.tipo_operacion"
                          @change="cambioOperacion"
                        >
                          <option
                            v-for="tipoOp in listasForms.tiposOperacion"
                            :key="tipoOp.numeracion"
                            :value="tipoOp.numeracion"
                          >
                            {{ tipoOp.descripcion }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-8"></div>
                    <div class="col-md-2">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn bg-gradient-navy mt-4 shadow"
                          @click="getIndex()"
                          :disabled="$v.filtros.$invalid"
                        >
                          <i class="fas fa-search"></i>Buscar
                        </button>

                        <button
                          type="button"
                          class="btn bg-gradient-danger mt-4 shadow"
                          @click="limpiar(1)"
                        >
                          <i class="fas fa-broom"></i>Limpiar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="card-body"> -->
                <div class="card mb-0">
                  <div class="card-header bg-gradient-gray pt-2 pb-2">
                    <h3 class="card-title">
                      <i class="fas fa-filter"></i>
                      Filtros
                    </h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body" style="display: block">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row justify-content-center">
                          <div class="form-group col-md-2">
                            <label for="fecha">Fecha Inicial</label>
                            <input
                              type="date"
                              class="form-control form-control-sm shadow-sm"
                              v-model="filtros.fechaInicial"
                              @input="validarFechas"
                              :class="
                                $v.filtros.fechaInicial.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label for="fecha">Fecha Final</label>
                            <input
                              type="date"
                              class="form-control form-control-sm shadow-sm"
                              v-model="filtros.fechaFinal"
                              @input="validarFechas"
                              :class="
                                $v.filtros.fechaFinal.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            />
                          </div>
                          <div
                            class="form-group col-md-2"
                            v-if="filtros.tipo_operacion == 2"
                          >
                            <label for="numero_guia"># Guía</label>
                            <input
                              type="number"
                              class="form-control form-control-sm shadow-sm"
                              placeholder="Número"
                              v-model="filtros.numero"
                            />
                          </div>

                          <div class="form-group col-md-4">
                            <label>Transportadora</label>
                            <v-select
                              class="shadow-sm"
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="transportadora"
                              placeholder="Razon social"
                              label="razon_social"
                              :options="listasForms.empresas"
                              @input="seleccionarTransportadora()"
                            ></v-select>
                          </div>
                          <div
                            class="form-group col-md-2"
                            v-if="filtros.tipo_operacion != 2"
                          >
                            <label>Campo</label>
                            <v-select
                              class="shadow-sm"
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              multiple
                              v-model="filtros.sitios"
                              placeholder="Sitio"
                              label="nombre"
                              :options="listasForms.sitios"
                              :reduce="(sitio) => sitio.id"
                            ></v-select>
                          </div>
                          <div
                            class="form-group col-md-2"
                            v-if="
                              filtros.tipo_operacion != 2 &&
                                filtros.tipo_operacion != 4
                            "
                          >
                            <label for="tipoRuta"> Tipo de Ruta</label>
                            <select
                              class="form-control form-control-sm shadow-sm"
                              v-model="filtros.tipoRuta"
                            >
                              <option value="">Todos...</option>
                              <option
                                v-for="tipoRuta in listasForms.tipoRutas"
                                :key="tipoRuta.numeracion"
                                :value="tipoRuta.numeracion"
                              >
                                {{ tipoRuta.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="form-group col-md-2"
                            v-if="
                              filtros.tipo_operacion != 2 &&
                                filtros.tipo_operacion != 4
                            "
                          >
                            <label for="tipoProducto"> Tipo de Producto</label>
                            <select
                              class="form-control form-control-sm shadow-sm "
                              v-model="filtros.tipoProducto"
                            >
                              <option value="">Todos...</option>
                              <option
                                v-for="tipoProducto in listasForms.tipos_producto"
                                :key="tipoProducto.numeracion"
                                :value="tipoProducto.numeracion"
                              >
                                {{ tipoProducto.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="form-group col-md-2"
                            v-if="filtros.tipo_operacion == 2"
                          >
                            <label for="tipoProducto">Tipo de Producto</label>
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="tipo_producto"
                              placeholder="Tipo Producto"
                              label="nombre"
                              :options="listasForms.tipos_producto"
                              @input="seleccionarTipoProducto()"
                            ></v-select>
                          </div>
                          <div
                            class="form-group col-md-2"
                            v-if="
                              filtros.tipo_operacion == 2 ||
                                filtros.tipo_operacion == 6 ||
                                filtros.tipo_operacion == 4
                            "
                          >
                            <label for="producto">Producto</label>
                            <input
                              type="text"
                              class="form-control form-control-sm shadow-sm"
                              placeholder="Producto"
                              v-model="filtros.producto"
                            />
                          </div>
                          <div
                            class="form-group col-md-1"
                            v-if="
                              filtros.tipo_operacion != 2 &&
                                filtros.tipo_operacion != 6 &&
                                filtros.tipo_operacion != 4
                            "
                          >
                            <label>Cita Cargue</label>
                            <input
                              type="text"
                              class="form-control form-control-sm shadow-sm"
                              placeholder="Cita cargue"
                              v-model="filtros.citaCargue"
                              :class="
                                $v.filtros.citaCargue.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              v-mask="{
                                mask: '99:99',
                                hourFormat: '24',
                              }"
                            />
                          </div>
                          <div
                            class="form-group col-md-1"
                            v-if="
                              filtros.tipo_operacion != 2 &&
                                filtros.tipo_operacion != 6 &&
                                filtros.tipo_operacion != 4
                            "
                          >
                            <label for="tipoSolicitud"> Tipo Solicitud</label>
                            <select
                              class="form-control form-control-sm shadow-sm "
                              v-model="filtros.tipoSolicitud"
                            >
                              <option value="">Todos...</option>
                              <option
                                v-for="tipoSolicitud in listasForms.tipos_solicitud"
                                :key="tipoSolicitud.numeracion"
                                :value="tipoSolicitud.numeracion"
                              >
                                {{ tipoSolicitud.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group col-md-4">
                            <label>Tipo Vehículo</label>
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="tipoVehiculo"
                              label="nombre"
                              :options="listasForms.tipo_vehiculos"
                              class="form-control form-control-sm shadow-sm p-0"
                              placeholder="Tipos de Vehículo"
                              @input="seleccionarTipoVehiculo()"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-2">
                            <label>Tip. Combustible</label>
                            <v-select
                              class="shadow-sm"
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="tipo_combustible"
                              placeholder="Combustible"
                              label="descripcion"
                              :options="listasForms.tiposCombustible"
                              @input="seleccionarTipoCombustible()"
                            ></v-select>
                          </div>
                          <div class="form-group col-md-2">
                            <label>Vehiculo</label>
                            <input
                              type="text"
                              class="form-control form-control-sm shadow-sm"
                              placeholder="Placa"
                              v-model="filtros.vehiculo_id"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label>Remolque</label>
                            <input
                              type="text"
                              class="form-control form-control-sm shadow-sm"
                              placeholder="Placa"
                              v-model="filtros.remolque_id"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label>Conductor</label>
                            <input
                              type="text"
                              class="form-control form-control-sm shadow-sm"
                              placeholder="Conductor"
                              v-model="filtros.conductor_id"
                            />
                          </div>
                          <div class="form-group col-md-2">
                            <label>Estado</label>
                            <select
                              class="form-control form-control-sm shadow-sm"
                              v-model="filtros.estado"
                            >
                              <option value="">Todos...</option>
                              <option
                                v-for="estado in listasForms.estados"
                                :key="estado.numeracion"
                                :value="estado.numeracion"
                              >
                                {{ estado.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="form-group col-md-2"
                            v-if="
                              filtros.tipo_operacion != 2 &&
                                filtros.tipo_operacion != 6
                            "
                          >
                            <label>Orden de Cargue</label>
                            <input
                              type="text"
                              class="form-control form-control-sm shadow-sm"
                              placeholder="Orden de cargue"
                              v-model="filtros.ordenCargue"
                            />
                          </div>
                          <div
                            class="form-group col-md-2"
                            v-if="filtros.tipo_operacion == 2"
                          >
                            <label>Tipo Sitio</label>
                            <select
                              class="form-control form-control-sm shadow-sm"
                              v-model="sitio_type"
                              @change="selectSitioType()"
                            >
                              <option value="">Todos...</option>
                              <option
                                v-for="listaSitio in listasForms.sitio_type"
                                :key="listaSitio.numeracion"
                                :value="listaSitio.numeracion"
                              >
                                {{ listaSitio.descripcion }}
                              </option>
                            </select>
                          </div>
                          <div
                            class="form-group col-md-4"
                            v-if="
                              this.filtros.sitio_type == 'App\\Sitio' &&
                                filtros.tipo_operacion == 2
                            "
                          >
                            <label>Sitio</label>
                            <v-select
                              class="shadow-sm"
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              multiple
                              v-model="filtros.sitios"
                              placeholder="Sitio"
                              label="nombre"
                              :options="listasForms.sitios"
                              :reduce="(sitio) => sitio.id"
                            ></v-select>
                          </div>
                          <div
                            class="form-group col-md-4"
                            v-if="
                              this.filtros.sitio_type == 'App\\PuntoGestion'
                            "
                          >
                            <label>Punto Gestión</label>
                            <v-select
                              v-model="punto_gestion"
                              class="form-control form-control-sm p-0 shadow-sm"
                              placeholder="Nombre"
                              label="nombre"
                              :options="listasForms.puntos_gestion"
                              @input="selectPunto()"
                            ></v-select>
                          </div>
                          <div
                            class="form-group col-md-4"
                            v-if="filtros.tipo_operacion == 2"
                          >
                            <label>Sitio Destino</label>
                            <v-select
                              class="shadow-sm"
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="sitio_destino"
                              placeholder="Sitio Destino"
                              label="nombre"
                              :options="listasForms.sitios"
                              @input="seleccionarSitioDestino()"
                            ></v-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- </div> -->
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      id="tab-Tabla"
                      data-toggle="tab"
                      href="#Tabla"
                      >Tabla</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      id="tab-Grafica"
                      data-toggle="tab"
                      href="#Grafica"
                      >Gráfica</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade active show" id="Tabla">
                    <div class="card">
                      <div class="card-body table-responsive p-0">
                        <div class="row">
                          <div class="col-md-12">
                            <table
                              class="
                              table
                              table-bordered
                              table-striped
                              table-hover
                              table-sm
                              text-nowrap
                            "
                              style="font-size: 0.8em"
                            >
                              <thead class="thead">
                                <tr>
                                  <th class="text-center">#</th>
                                  <th>Fecha</th>
                                  <th v-if="filtros.tipo_operacion == 2">
                                    Numero Guía
                                  </th>
                                  <th>
                                    Empresa
                                  </th>
                                  <th v-if="filtros.tipo_operacion != 2">
                                    Campo
                                  </th>
                                  <th v-if="filtros.tipo_operacion == 2">
                                    Sitio / Punto Gestión
                                  </th>
                                  <th v-if="filtros.tipo_operacion == 2">
                                    Sitio Destino
                                  </th>
                                  <th v-if="filtros.tipo_operacion == 6">
                                    Ruta
                                  </th>
                                  <th
                                    v-else-if="
                                      filtros.tipo_operacion != 2 &&
                                        filtros.tipo_operacion != 4
                                    "
                                  >
                                    Tipo Ruta
                                  </th>
                                  <th>Tipo Vehículo</th>
                                  <th
                                    v-if="
                                      filtros.tipo_operacion != 2 &&
                                        filtros.tipo_operacion != 6 &&
                                        filtros.tipo_operacion != 4
                                    "
                                  >
                                    Cita Cargue
                                  </th>
                                  <th
                                    v-if="
                                      filtros.tipo_operacion != 4 &&
                                        filtros.tipo_operacion != 6
                                    "
                                  >
                                    Tipo Producto
                                  </th>
                                  <th
                                    v-if="
                                      filtros.tipo_operacion == 2 ||
                                        filtros.tipo_operacion == 6 ||
                                        filtros.tipo_operacion == 4
                                    "
                                  >
                                    Producto
                                  </th>
                                  <th
                                    v-if="
                                      filtros.tipo_operacion != 2 &&
                                        filtros.tipo_operacion != 6 &&
                                        filtros.tipo_operacion != 4
                                    "
                                  >
                                    Tipo Solicitud
                                  </th>
                                  <th>Vehículo</th>
                                  <th>Remolque</th>
                                  <th>Documento Conductor</th>
                                  <th>Nombre Conductor</th>
                                  <th
                                    v-if="
                                      filtros.tipo_operacion != 2 &&
                                        filtros.tipo_operacion != 6
                                    "
                                  >
                                    Orden Cargue
                                  </th>
                                  <th
                                    v-if="
                                      filtros.tipo_operacion != 2 &&
                                        filtros.tipo_operacion != 6
                                    "
                                  >
                                    Orden
                                  </th>
                                  <th>Estado</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item, index) in postulaciones"
                                  :key="item.id"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ item.fecha }}</td>
                                  <td v-if="filtros.tipo_operacion == 2">
                                    {{ item.numero_guia }}
                                  </td>
                                  <td>
                                    {{ item.empresa }}
                                  </td>
                                  <td>
                                    <span
                                      v-if="item.sitio_type"
                                      class="badge"
                                      :class="
                                        item.sitio_type == 'App\\Sitio'
                                          ? 'bg-info'
                                          : 'bg-info'
                                      "
                                    >
                                      {{
                                        item.sitio_type == "App\\Sitio"
                                          ? "Sitio"
                                          : "Punto Gestión"
                                      }}</span
                                    >
                                    {{ item.campo }}
                                  </td>
                                  <td v-if="filtros.tipo_operacion == 2">
                                    {{ item.sitio_destino }}
                                  </td>
                                  <td
                                    v-if="
                                      filtros.tipo_operacion != 2 &&
                                        filtros.tipo_operacion != 4
                                    "
                                  >
                                    {{ item.tipo_ruta }}
                                  </td>
                                  <td>{{ item.tipo_vehiculo }}</td>
                                  <td
                                    v-if="
                                      filtros.tipo_operacion != 2 &&
                                        filtros.tipo_operacion != 6 &&
                                        filtros.tipo_operacion != 4
                                    "
                                  >
                                    {{ item.cita_cargue }}
                                  </td>
                                  <td
                                    v-if="
                                      filtros.tipo_0operacion != 4 &&
                                        filtros.tipo_operacion != 6
                                    "
                                  >
                                    {{ item.tipo_producto }}
                                  </td>
                                  <td
                                    v-if="
                                      filtros.tipo_operacion == 2 ||
                                        filtros.tipo_operacion == 6 ||
                                        filtros.tipo_operacion == 4
                                    "
                                  >
                                    {{ item.producto }}
                                  </td>
                                  <td
                                    v-if="
                                      filtros.tipo_operacion != 2 &&
                                        filtros.tipo_operacion != 6 &&
                                        filtros.tipo_operacion != 4
                                    "
                                  >
                                    {{ item.tipo_solicitud }}
                                  </td>
                                  <td class="text-center">
                                    {{ item.vehiculo }}
                                    <div>
                                      <span
                                        v-if="item.tipo_combustible != null"
                                        class="badge ml-1 mr-1"
                                        :class="
                                          item.tipo_combustible == 1
                                            ? 'badge-warning'
                                            : 'badge-info'
                                        "
                                      >
                                        {{ item.nCombustible }}
                                      </span>
                                    </div>
                                  </td>
                                  <td>{{ item.remolque }}</td>
                                  <td>{{ item.cedula_conductor }}</td>
                                  <td>{{ item.nombre_conductor }}</td>
                                  <td
                                    v-if="
                                      filtros.tipo_operacion != 2 &&
                                        filtros.tipo_operacion != 6
                                    "
                                  >
                                    {{ item.orden_cargue }}
                                  </td>
                                  <td
                                    v-if="
                                      filtros.tipo_operacion != 2 &&
                                        filtros.tipo_operacion != 6
                                    "
                                  >
                                    <button
                                      type="button"
                                      class="btn btn-navy btn-sm "
                                      @click="verFoto(item.link_orden)"
                                      v-if="item.link_orden != ''"
                                    >
                                      <i class="fas fa-eye"></i>
                                    </button>
                                  </td>
                                  <td class="text-center">
                                    <div
                                      v-if="
                                        item.estado !== null &&
                                          item.estado != ''
                                      "
                                    >
                                      <div v-if="item.n_estado == 1">
                                        <span class="badge badge-warning">
                                          {{ item.estado }}
                                        </span>
                                      </div>
                                      <div v-if="item.n_estado == 2">
                                        <span class="badge badge-primary">
                                          {{ item.estado }}
                                        </span>
                                      </div>
                                      <div v-if="item.n_estado == 3">
                                        <span class="badge bg-maroon">
                                          {{ item.estado }}
                                        </span>
                                      </div>
                                      <div v-if="item.n_estado == 4">
                                        <span class="badge badge-success">
                                          {{ item.estado }}
                                        </span>
                                      </div>
                                      <div v-if="item.n_estado == 5">
                                        <span class="badge badge-danger">
                                          {{ item.estado }}
                                        </span>
                                      </div>
                                      <div v-if="item.n_estado == 6">
                                        <span class="badge badge-success">
                                          {{ item.estado }}
                                        </span>
                                      </div>
                                      <div v-if="item.n_estado == 7">
                                        <span class="badge badge-dark">
                                          {{ item.estado }}
                                        </span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="Grafica">
                    <div class="row">
                      <div class="col-md-12">
                        <GraficaReportePostulacion
                          ref="GraficaReportePostulacion"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="postulaciones.length > 0">
                    <p>
                      Mostrando del <b>1</b> al
                      <b>{{ postulaciones.length }}</b> de un total:
                      <b>{{ postulaciones.length }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <!-- <pagination
                    :data="postulaciones"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    align="right"
                  ></pagination> -->
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import { required, helpers } from "vuelidate/lib/validators";
//import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import axios from "axios";
import Loading from "../../../../components/Loading";
import GraficaReportePostulacion from "./GraficaReportePostulacion";
const timeFormat = helpers.regex(
  "timeFormat",
  /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/
);
export default {
  name: "ReportePostulacionIndex",
  components: {
    Loading,
    // pagination,
    vSelect,
    GraficaReportePostulacion,
  },
  data() {
    return {
      cargando: false,
      postulaciones: [],
      filtros: {
        fechaInicial: null,
        fechaFinal: null,
        tipoProducto: null,
        tipo_operacion: 1,
      },
      conductor: null,
      vehiculo: null,
      remolque: null,
      tipo_combustible: null,
      transportadora: null,
      ruta: null,
      sitio: null,
      sitio_destino: null,
      tipoVehiculo: null,
      sitio_type: null,
      punto_gestion: null,
      tipo_producto: null,
      listasForms: {
        estados: [],
        sitios: [],
        conductores: [],
        rutas: [],
        cabezotes: [],
        tiposCombustible: [],
        remolques: [],
        empresas: [],
        listaConsultas: [],
        tipoRutas: [],
        tipo_vehiculos: [],
        tipos_producto: [],
        tipos_solicitud: [],
        tiposOperacion: [],
        sitio_type: [],
        puntos_gestion: [],
      },
    };
  },
  validations: {
    filtros: {
      fechaInicial: { required },
      fechaFinal: { required },
      citaCargue: { timeFormat },
    },
  },
  methods: {
    validarFechas() {
      if (this.filtros.fechaInicial) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.filtros.fechaInicial);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.filtros.fechaInicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fechaInicial && this.filtros.fechaFinal) {
        var fecha_menor = new Date(this.filtros.fechaInicial);
        var fecha_mayor = new Date(this.filtros.fechaFinal);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.filtros.fechaFinal = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fechaInicial && this.filtros.fechaFinal) {
        fecha_menor = new Date(this.filtros.fechaInicial).getTime();
        fecha_mayor = new Date(this.filtros.fechaFinal).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 31) {
          this.filtros.fechaFinal = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 31 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.filtros.fechaFinal) {
        var hoy = new Date();
        const fecha_final = new Date(this.filtros.fechaFinal);
        fecha_final.setDate(fecha_final.getDate());
        if (fecha_final >= hoy + 1) {
          this.filtros.fechaFinal = null;
          this.$swal({
            icon: "error",
            title: `La fecha final debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get(
          "/api/hidrocarburos/postulaciones/reportePostulacion?page=" + page,
          {
            params: this.filtros,
          }
        )
        .then((response) => {
          this.cargando = false;
          this.postulaciones = response.data.arregloTotal;
          this.$refs.GraficaReportePostulacion.graficaPostulacion(
            response.data.arregloGrafica
          );
        })
        .catch(function(error) {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    limpiar(accion) {
      this.transportadora = null;
      this.sitio = null;
      this.tipoVehiculo = null;
      this.filtros.fechaInicial = "";
      this.filtros.fechaFinal = "";
      this.filtros.transportadora_id = "";
      this.filtros.sitio_id = "";
      this.filtros.tipoRuta = "";
      this.filtros.tipoProducto = "";
      this.filtros.tipo_vehiculo_id = "";
      this.filtros.citaCargue = "";
      this.filtros.tipoSolicitud = "";
      this.filtros.vehiculo_id = "";
      this.filtros.tipo_combustible = "";
      this.filtros.remolque_id = "";
      this.filtros.conductor_id = "";
      this.filtros.ordenCargue = "";
      this.filtros.estado = "";
      this.filtros.producto_id = "";
      this.filtros.producto = "";
      this.filtros.destino_id = "";
      if (accion == 1) {
        this.filtros.tipo_operacion = 1;
        this.cambioOperacion();
      }
    },

    getTiposOperacion() {
      axios.get("/api/lista/182").then((response) => {
        this.listasForms.tiposOperacion = response.data;
      });
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarVehiculo() {
      this.filtros.vehiculo_id = this.vehiculo ? this.vehiculo.id : null;
    },

    seleccionarRemolque() {
      this.filtros.remolque_id = this.remolque ? this.remolque.id : null;
    },

    seleccionarTipoCombustible() {
      this.filtros.tipo_combustible = this.tipo_combustible
        ? this.tipo_combustible.numeracion
        : null;
    },

    seleccionarTipoProducto() {
      this.filtros.tipoProducto = this.tipo_producto
        ? this.tipo_producto.id
        : null;
    },

    seleccionarTransportadora() {
      this.filtros.transportadora_id = this.transportadora
        ? this.transportadora.id
        : null;
    },

    seleccionarConductor() {
      this.filtros.conductor_id = this.conductor ? this.conductor.id : null;
    },

    seleccionarSitio() {
      this.filtros.sitio_id = this.sitio ? this.sitio.id : null;
    },

    seleccionarSitioDestino() {
      this.filtros.destino_id = this.sitio_destino
        ? this.sitio_destino.id
        : null;
    },

    seleccionarTipoVehiculo() {
      this.filtros.tipo_vehiculo_id = this.tipoVehiculo
        ? this.tipoVehiculo.id
        : null;
    },

    getTiposCombustibles() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.tiposCombustible = response.data;
      });
    },

    getTipoRutas() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipoRutas = response.data;
      });
    },

    async getTiposVehiculos(tipo_vehiculos) {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            //linea_negocio_id: 1,
            //not_id: tipo_vehiculos,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipo_vehiculos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoProductos() {
      axios.get("/api/lista/7").then((response) => {
        this.listasForms.tipos_producto = response.data;
      });
    },

    getTipoSolicitud() {
      axios.get("/api/lista/110").then((response) => {
        this.listasForms.tipos_solicitud = response.data;
      });
    },

    buscarConductores() {
      let me = this;
      var url = "api/admin/conductores/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.conductores = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarCabezotes() {
      let me = this;
      var url = "api/admin/vehiculos/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.cabezotes = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarRemolques() {
      let me = this;
      var url = "api/admin/remolques/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.remolques = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    verFoto(url) {
      var win = window.open(url, "_blank");
      // Cambiar el foco al nuevo tab (punto opcional)
      win.focus();
    },

    cambioOperacion() {
      this.limpiar(2);
      this.postulaciones = [];
      switch (this.filtros.tipo_operacion) {
        case 2:
          axios.get("/api/lista/39").then((response) => {
            this.listasForms.estados = response.data;
          });

          axios.get("api/admin/productos/lista").then((response) => {
            this.listasForms.tipos_producto = response.data;
          });
          break;
        case 6:
          this.listasForms.estados = [];
          axios.get("/api/lista/65").then((response) => {
            response.data.forEach((element) => {
              if (
                element.numeracion != 4 &&
                element.numeracion != 5 &&
                element.numeracion != 6
              ) {
                this.listasForms.estados.push(element);
              }
            });
          });
          break;
        case 4:
          this.listasForms.estados = [];
          axios.get("/api/lista/65").then((response) => {
            response.data.forEach((element) => {
              if (
                element.numeracion != 4 &&
                element.numeracion != 5 &&
                element.numeracion != 6
              ) {
                this.listasForms.estados.push(element);
              }
            });
          });
          break;
        default:
          axios.get("/api/lista/65").then((response) => {
            this.listasForms.estados = response.data;
          });
          this.getTipoProductos();

          break;
      }
    },

    getSitioType() {
      axios.get("/api/lista/25").then((response) => {
        this.listasForms.sitio_type = response.data;
      });
    },

    selectSitioType() {
      this.filtros.sitio_type = null;
      this.filtros.sitio_id = null;
      if (this.sitio_type == 1) {
        this.filtros.sitio_type = "App\\Sitio";
      }

      if (this.sitio_type == 2) {
        this.filtros.sitio_type = "App\\PuntoGestion";
      }
      this.getIndex();
    },

    getPuntos() {
      axios
        .get("/api/admin/puntos_gestion/lista", {
          params: {
            estado: 1,
          },
        })
        .then((response) => {
          this.listasForms.puntos_gestion = response.data;
        });
    },

    selectPunto() {
      this.filtros.sitio_id = null;
      if (this.punto_gestion) {
        this.filtros.sitio_id = this.punto_gestion.id;
      }
      this.getIndex();
    },
  },

  mounted() {
    this.getPuntos();
    this.getTiposOperacion();
    this.cambioOperacion();
    this.buscarTransportadora();
    this.getSitioType();
    this.buscarSitios();
    this.getTipoRutas();
    this.getTiposVehiculos();
    this.getTipoProductos();
    this.getTipoSolicitud();
    this.buscarCabezotes();
    this.getTiposCombustibles();
    this.buscarRemolques();
    this.buscarConductores();
  },
};
</script>
